@import 'design-system/styles';

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-4xl;
}

.program-card {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: $spacing-3xl;
  border-radius: $spacing-xl;
  border: 1px solid $neutral-200;
  background: $white;
  box-shadow: $shadow-sm;
  gap: $spacing-5xl;
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
}

.cta-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.cta {
  display: flex;
  align-items: center;

  .icon-container {
    padding-left: $spacing-md;
    display: flex;

    @include override-icon-color($purple-700);
  }
}
