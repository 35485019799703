@import 'design-system/styles';

.user-list {
  position: relative;
  display: flex;
  margin-left: size(9.12);
  cursor: pointer;
}

.avatar-wrapper {
  width: size(42);
  height: size(42);
  position: relative;
  display: block;
  background-color: $white;
  z-index: var(--z-index);
  border: 1px solid $neutral-200;
  border-radius: 50%;
  margin-left: -(size(9.12));
  transition: transform 0.2s ease-in-out;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(0.7);
    transform-origin: center;
  }

  // Avatar initials
  span {
    margin-left: $spacing-xs;
    margin-top: $spacing-xs;
  }

  &:hover,
  &:focus {
    z-index: calc(100 + var(--z-index));
    transform: translateY(-(size(3)));
  }

  &:focus {
    box-shadow: 0 $sizing-xxs $sizing-xs rgb(0 0 0 / 5%);
  }
}

.modal-user-list {
  overflow-y: scroll;
  max-height: 60vh;
}

.modal-user-wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  align-items: center;
  padding: $spacing-md 0;
  border-bottom: 1px solid $neutral-200;
}

.overflow-avatar {
  background-color: $neutral-50;

  .overflow-text {
    color: $neutral-700;
  }
}
