@import 'design-system/styles';

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
  margin-bottom: $spacing-4xl;
}

.title {
  display: flex;
  gap: $spacing-xl;
  align-items: center;
}

.subtitle {
  margin-top: $spacing-xl;
}

.content {
  display: flex;
  gap: $spacing-xl;
  flex-wrap: wrap;
  align-items: center;

  > :first-child {
    flex: 1;
  }

  > :last-child {
    flex: 0 1 auto;
  }
}
