@import 'design-system/styles';

.section-heading {
  margin-top: $spacing-5xl;
  margin-bottom: $spacing-4xl;
}

.grid {
  grid-row-gap: $spacing-2xl;
}

.badges-heading-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border: none;
    box-shadow: none;
  }
}

.single-program-container {
  width: 480px;
  margin: auto;
}
