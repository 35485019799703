@import 'design-system/styles';

@mixin box-border {
  box-shadow: $shadow-xs;
  border: 1px solid $neutral-200;
  border-radius: $spacing-xl;
}

.enrollment-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-4xl;
  padding: $spacing-5xl;
  flex: 1;
  margin: auto 0;
  @include box-border;
}

.header {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

.section-heading {
  margin-top: $spacing-5xl;
  margin-bottom: $spacing-4xl;
}

.enrollment-title {
  font-weight: 400;
}

.icon-box {
  box-shadow: $shadow-xs;
  border: 1px solid $neutral-200;
  padding: $spacing-lg;
  border-radius: 10px;

  > img {
    width: $spacing-3xl;
  }
}

.enrollment-row {
  display: flex;
  align-items: center;
  gap: $spacing-xl;
  width: 100%;
}

.row-content {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.link {
  margin-left: auto;
  justify-self: flex-end;
}

.section {
  display: flex;
  gap: $spacing-3xl;
  flex-wrap: wrap;
}

.dates-and-costs-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;
  flex: 1;
  margin: auto 0;
}

.dates-and-costs-row {
  padding: $spacing-4xl;
  flex: 1;
  gap: $spacing-3xl;
  display: flex;
  flex-direction: column;
  @include box-border;
}

.single-date-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-sm;
}

.single-date-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-xl;
}

.program-cost {
  display: flex;
  justify-content: space-between;
}

.program-costs-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
}

.program-cost-wrapper {
  width: 100%;
  padding: $spacing-4xl;
  margin: 0 100px;
  gap: $spacing-4xl;
  display: flex;
  flex-direction: column;
  @include box-border;
}

.multi-date-content {
  display: flex;
  align-self: flex-start;
  gap: $spacing-xl;

  > div {
    display: flex;
    flex-direction: column;
    gap: $spacing-sm;
  }
}

.multi-date-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-3xl;
}
