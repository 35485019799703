@import 'design-system/styles';

// Shared scrollbar styles mixin - consider moving to global styles
@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 4px; // Skinny scrollbar width
  }

  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: $neutral-200;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $neutral-300;
  }
}

.dual-sidenav-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: sticky;
  top: 0;
  margin-left: calc(-1 * $sizing-6xl);
  overflow: hidden;
  white-space: nowrap;
  z-index: 1300; // beneath the MUI snackbar - but ensures overlay slides above any other page element (eg marketplace filters).

  @include breakpoint-up('desktop') {
    margin-left: size(-60);
  }

  @include breakpoint-down('tablet') {
    overflow: hidden;
    margin-left: 0;
    margin-right: calc(-1 * $sizing-4xl);
  }

  // remove default focus ring
  *:focus {
    outline: none;
    border-radius: $spacing-sm;
  }
}

.primary-nav {
  width: $sizing-7xl;
  height: 100vh;
  background-color: $white;
  border-right: 1px solid $neutral-200;
  flex-shrink: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  @include custom-scrollbar;

  .icon-only {
    padding: $spacing-lg $spacing-2xl;
    width: 52px;
    justify-content: center;
    margin: 0 $sizing-sm $sizing-xs;
    border-radius: 7px;

    > span:not(:first-child) {
      display: none;
    }

    &:hover,
    &:focus {
      @include focus-styles;

      border: none;
    }
  }

  .small {
    padding: $spacing-sm;
    margin: $spacing-sm auto $spacing-xl;
    width: auto;

    span {
      height: $sizing-3xl;
    }
  }

  .small-link[href] {
    padding: $spacing-sm;
    display: flex;
    align-self: center;
    width: 36px;
    margin-left: 13px; // 36px / 2 to center the icon
  }

  // Fix href icon alignment
  [href] {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-lg;

    &:hover,
    &:focus {
      @include focus-styles;

      background: $neutral-50;
      border: none;
    }
  }

  &-content {
    flex-shrink: 0; // Prevent content from shrinking
    padding: $spacing-xl 0;
    overflow-x: clip;

    .novi-bug-container {
      width: $spacing-7xl;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 $spacing-xl $spacing-xl $spacing-xl;
    }

    ul {
      display: flex;
      flex-direction: column;
    }
  }

  &-bottom {
    padding: $spacing-xl 0 $spacing-xs 0;

    .bottom-actions {
      display: flex;
      flex-direction: column;
      gap: $spacing-xs;
    }

    .avatar {
      padding: 0;
      width: 40px;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: $spacing-xl;
      border-radius: 40px;

      &:hover,
      &:focus {
        @include focus-styles;

        border: none;
      }
    }
  }
}

// Secondary Nav styles
.responsive-sidenav {
  width: size(258);
  height: 100vh;
  background-color: $white;
  border-right: 1px solid $neutral-200;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  top: 0;
  transition: transform 0.3s ease-in-out;
  @include custom-scrollbar;

  &.collapsed {
    transform: translateX(-258px);
    pointer-events: none;
  }

  &.overlay-mode {
    position: fixed;
    left: 0;
    width: 258px;
    transform: translateX($sizing-7xl);
    box-shadow: $shadow-md;

    &.collapsed {
      transform: translateX(-258px);
    }
  }

  .content {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: size(10);
    padding-right: $spacing-xxs;

    .disabled {
      pointer-events: none;
    }

    .title {
      padding-left: $spacing-lg;

      // Custom Subheading
      color: $neutral-400;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.28px;
      text-transform: uppercase;
    }

    .section {
      margin-bottom: $spacing-3xl;
    }

    a,
    button {
      width: calc(100% - $sizing-sm);
      margin-left: 0;
      text-decoration: none;

      &:hover,
      &:focus {
        @include focus-styles;

        border: none;
      }

      span {
        @include text-style('text-md-semibold');
      }
    }

    @include breakpoint-down('tablet') {
      width: auto;
    }

    .upper {
      width: 100%;
    }

    .header {
      width: 100%;
      margin-top: $spacing-xl;
      min-height: $sizing-xl;

      .logo-container {
        width: calc(100% - $spacing-md);
        height: $sizing-5xl;
        display: flex;
        align-items: center;
        margin-top: $spacing-3xl;
        margin-left: $spacing-sm;
        margin-bottom: $spacing-xl;
        text-decoration: none;
        white-space: normal;

        a {
          display: flex;
          border-radius: $spacing-sm;
          padding: $spacing-xxs;
        }

        // Loading state styles
        &--loading {
          background: $neutral-100;

          // Shimmer effect without position: absolute
          background: linear-gradient(
            90deg,
            $neutral-100 0%,
            $neutral-200 50%,
            $neutral-100 100%
          );
          background-size: 200% 100%;
          animation: shimmer 1.5s infinite linear;
        }

        img {
          width: auto;
          max-width: 100%;

          // height: $sizing-5xl;
          object-fit: contain;
        }
      }
    }

    .main {
      width: 100%;
      margin-top: $spacing-5xl;
    }

    .lower {
      display: block;
      width: 100%;

      .cta {
        margin: 0 0 $spacing-lg 0;
      }

      .settings {
        margin-bottom: $spacing-xs;
      }
    }

    .lower-bottom {
      margin-left: size(-10);
      margin-right: size(-2);
      padding: $spacing-xl $spacing-md $spacing-2xl $spacing-3xl;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $neutral-200;

      button {
        padding: $spacing-md;
        display: flex;
        align-items: center;
        border-radius: $spacing-sm;
        padding-left: $spacing-xxs;
      }

      .lower-bottom-text {
        display: flex;
        flex-direction: column;
        gap: $spacing-md;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}
