@import 'design-system/styles';

.container {
  border: size(1) solid $neutral-200;
  border-radius: size(sm);
  padding: size(md);
  background-color: $white;

  &.no-border {
    border: none;
  }

  &-inner {
    align-items: center;
    border-radius: size(sm);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: size(xxl) size(md);

    svg {
      max-width: $spacing-11xl;
    }

    & > *:last-child {
      margin-top: size(sm);
    }
  }

  .message {
    margin-top: $spacing-3xl;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

.loader {
  display: flex;
  align-items: center;
  gap: size(xxs);
}
