@import 'design-system/styles';

.container {
  display: flex;
  gap: $spacing-4xl;
  padding: $spacing-4xl;
  align-items: flex-start;
  border: 1px solid $neutral-200;
  border-radius: $spacing-xl;
  justify-content: space-between;
}

.content-container {
  display: flex;
  gap: $spacing-4xl;
  align-items: flex-start;
}

.image {
  align-self: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
}

.actions {
  display: flex;
  gap: 8px;
  align-items: center;
}
