@import 'design-system/styles';

$border: size(xs) solid transparent;
$colored-border: size(xs) solid $neutral-200;

.content {
  background-color: $purple-900;
  border: size(1) solid $purple-900;
  border-radius: size(10);
  box-shadow: 0 size(sm) size(md) rgb(0 0 0 / 15%);
  padding: size(xs) size(sm);
  max-width: size(300);
  white-space: normal;
  word-wrap: break-word;
  line-height: 1rem;
  color: $white;

  // Portal escapes stacking contexts, but z-index still needs to be above all MUI and nav elements
  z-index: 1500;
}

.trigger {
  color: inherit;
  cursor: pointer;
  border: none;
  background: transparent;
  width: fit-content;
}
