@import 'design-system/styles';

.root,
%root {
  @include text-style('text-xs-medium');

  background-color: $neutral-50;
  border-radius: $spacing-xl;
  border: size(1) solid $neutral-200;
  display: flex;
  gap: size(xxs);
  align-items: center;
  justify-content: center;
  padding: 0 $spacing-md;
  white-space: nowrap;
  width: fit-content;
}

.clickable {
  @include text-style('text-xs-bold');

  cursor: pointer;
  border: $sizing-xxs solid $neutral-200;
}

.active {
  background-color: $neutral-500;
  color: white;
}

.dark {
  @extend %root;

  border: 1px solid $neutral-500;
  background-color: $neutral-700;
  color: white;
}

.purple {
  @extend %root;

  @include pill-color('purple');
}

.blue {
  @extend %root;

  @include pill-color('blue');
}

.orange {
  @extend %root;

  @include pill-color('orange');
}

.red {
  @extend %root;

  @include pill-color('red');
}

.green {
  @extend %root;

  @include pill-color('green');
}

.brand-primary {
  @extend %root;

  @include pill-color('brand-primary');
}

.error {
  @extend %root;

  @include pill-color-brand('error');
}

.warning {
  @extend %root;

  @include pill-color-brand('warning');
}

.success {
  @extend %root;

  @include pill-color('success');
}

.gray {
  @extend %root;

  @include pill-color('gray');
}

.brand-secondary {
  @extend %root;

  @include pill-color-brand('brand-secondary');
}

.brand-pink {
  @extend %root;

  @include pill-color-brand('brand-pink');
}

.brand-neon {
  @extend %root;

  @include pill-color-brand('brand-neon');
}

.light-gray {
  @extend %root;

  background-color: $white;
  border-color: $gray-300;
  color: $gray-700;
}

.dark-gray {
  @extend %root;

  background-color: $gray-700;
  border-color: $gray-600;
  color: $gray-25;
}

.round {
  width: size(26);
  height: size(26);
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
