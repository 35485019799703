@import 'design-system/styles';

.trigger-button {
  margin-top: $spacing-xl;
}

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
  margin-top: $spacing-xl;
}
