@import 'design-system/styles';

.toast-viewport {
  --viewport-padding: 25px;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  max-width: calc(100% - var(--viewport-padding) * 2);
  margin: 0;
  list-style: none;
  z-index: $z-index-toast;
  outline: none;
}

.toast-root {
  @include border-radius(xl);

  background-color: $white;
  box-shadow: $shadow-lg;
  border: 1px solid $neutral-300;
  padding: $spacing-xl;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-xl;
}

.toast-root[data-state='open'] {
  animation: slide-in 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.toast-root[data-state='closed'] {
  animation: hide 100ms ease-in;
}

.toast-root[data-swipe='cancel'] {
  transform: translateY(0);
  transition: transform 200ms ease-out;
}

.toast-content {
  display: flex;
  gap: $spacing-xl;
  min-width: 25rem;
  align-items: flex-start;
  justify-content: space-between;
}

.icon-container,
.icon-inner {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container {
  width: $spacing-6xl;
  min-width: $spacing-6xl;
  height: $spacing-6xl;

  &-green {
    background-color: $green-50;
  }

  &-red {
    background-color: $red-50;
  }

  .icon-inner {
    width: $spacing-4xl;
    height: $spacing-4xl;

    &-green {
      background-color: $green-100;

      svg {
        fill: $green-700;
      }
    }

    &-red {
      background-color: $red-100;

      svg {
        fill: $red-700;
      }
    }
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(calc(100% + var(--viewport-padding)));
  }

  to {
    transform: translateY(0);
  }
}

@keyframes swipe-out {
  from {
    transform: translateY(var(--radix-toast-swipe-end-x));
  }

  to {
    transform: translateY(calc(100% + var(--viewport-padding)));
  }
}
