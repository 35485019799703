@import 'design-system/styles';

.container,
.panel {
  display: flex;
}

.container {
  flex-direction: column;
  margin-bottom: $spacing-xs;
  position: relative;

  & > h1,
  & > h2,
  & > h3 {
    margin: 0;
    margin-bottom: $spacing-xl;
  }
}

.panel {
  gap: $spacing-xl;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;

  &-wrapper {
    position: relative;
  }

  &::before {
    width: $sizing-4xl;
    height: 100%;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    background: linear-gradient(to right, transparent, $neutral-200);
    opacity: 0;
    transition: opacity 0.2s ease;
    border-top-right-radius: $sizing-md;
  }

  &:not([data-scroll-end='true']) {
    &::before {
      opacity: 1;
    }
  }
}

.filters-and-actions {
  display: flex;
  gap: $spacing-md;
}
