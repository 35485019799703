@import 'design-system/styles';

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid $neutral-200;
  border-radius: $spacing-xl;
  padding: $spacing-4xl;
  gap: $spacing-5xl;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;
}

.expandable-section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
}

.expandable-section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.review-link {
  width: 100%;
  justify-content: center;
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}

.pill {
  border: 1px solid $neutral-300;
  box-shadow: $shadow-xs;
  padding: $spacing-xs $spacing-md;
  border-radius: $spacing-sm;
}
