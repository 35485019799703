@use 'sass:map';
@import './variables';

// BREAKPOINTS

@mixin breakpoint-up($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    @media (min-width: size(map-get($breakpoints, $breakpoint))) {
      @content;
    }
  } @else {
    @media (min-width: size($breakpoint)) {
      @content;
    }
  }
}

@mixin breakpoint-down($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    @media (max-width: size(map-get($breakpoints, $breakpoint))) {
      @content;
    }
  } @else {
    @debug $breakpoint;
    @media (max-width: size($breakpoint)) {
      @content;
    }
  }
}

// TEXT STYLES

@mixin text-style($kind) {
  @if index($text-kinds, $kind) {
    @if $kind == 'display-2xl' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(72);
      letter-spacing: -0.09rem;
      line-height: 125%;
    }
    @if $kind == 'display-2xl-medium' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(72);
      letter-spacing: -0.09rem;
      line-height: 125%;
    }
    @if $kind == 'display-2xl-bold' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(72);
      letter-spacing: -0.09rem;
      line-height: 125%;
    }
    @if $kind == 'display-xl' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(60);
      letter-spacing: -0.075rem;
      line-height: 125%;
    }
    @if $kind == 'display-xl-medium' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(60);
      letter-spacing: -0.075rem;
      line-height: 125%;
    }
    @if $kind == 'display-xl-bold' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(60);
      letter-spacing: -0.075rem;
      line-height: 125%;
    }
    @if $kind == 'display-lg' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(48);
      letter-spacing: -0.06rem;
      line-height: 125%;
    }
    @if $kind == 'display-lg-medium' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(48);
      letter-spacing: -0.06rem;
      line-height: 125%;
    }
    @if $kind == 'display-lg-bold' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(48);
      letter-spacing: -0.06rem;
      line-height: 125%;
    }
    @if $kind == 'display-md' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(36);
      line-height: 2.75rem;
    }
    @if $kind == 'display-md-medium' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(36);
      line-height: 2.75rem;
    }
    @if $kind == 'display-md-bold' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(36);
      line-height: 2.75rem;
    }
    @if $kind == 'display-sm' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(30);
      line-height: 2.375rem;
    }
    @if $kind == 'display-sm-medium' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(30);
      line-height: 2.375rem;
    }
    @if $kind == 'display-sm-bold' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(30);
      line-height: 2.375rem;
    }
    @if $kind == 'display-xs' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(24);
      line-height: 2rem;
    }
    @if $kind == 'display-xs-medium' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(24);
      line-height: 2rem;
    }
    @if $kind == 'display-xs-bold' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(24);
      line-height: 2rem;
    }
    @if $kind == 'display-2xs' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(20);
      line-height: 2rem;
    }
    @if $kind == 'display-2xs-medium' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(20);
      line-height: 2rem;
    }
    @if $kind == 'display-2xs-bold' {
      color: $neutral-900;
      font-family: Circular, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(20);
      line-height: 2rem;
    }
    @if $kind == 'text-xl' {
      font-family: Inter, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(20);
      line-height: 1.875rem;
    }
    @if $kind == 'text-xl-medium' {
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(20);
      line-height: 1.875rem;
    }
    @if $kind == 'text-xl-semibold' {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: px-to-rem(20);
      line-height: 1.875rem;
    }
    @if $kind == 'text-xl-bold' {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(20);
      line-height: 1.875rem;
    }
    @if $kind == 'text-lg' {
      font-family: Inter, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(18);
      line-height: 1.75rem;
    }
    @if $kind == 'text-lg-medium' {
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(18);
      line-height: 1.75rem;
    }
    @if $kind == 'text-lg-semibold' {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: px-to-rem(18);
      line-height: 1.75rem;
    }
    @if $kind == 'text-lg-bold' {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(18);
      line-height: 1.75rem;
    }
    @if $kind == 'text-md' {
      font-family: Inter, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(16);
      line-height: 1.5rem;
    }
    @if $kind == 'text-md-medium' {
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(16);
      line-height: 1.5rem;
    }
    @if $kind == 'text-md-semibold' {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: px-to-rem(16);
      line-height: 1.5rem;
    }
    @if $kind == 'text-md-bold' {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(16);
      line-height: 1.5rem;
    }
    @if $kind == 'text-sm' {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: px-to-rem(14);
      line-height: 1.25rem;
    }
    @if $kind == 'text-sm-medium' {
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(14);
      line-height: 1.25rem;
    }
    @if $kind == 'text-sm-semibold' {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: px-to-rem(14);
      line-height: 1.25rem;
    }
    @if $kind == 'text-sm-bold' {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(14);
      line-height: 1.25rem;
    }
    @if $kind == 'text-xs' {
      font-family: Inter, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: px-to-rem(12);
      line-height: 1.125rem;
    }
    @if $kind == 'text-xs-medium' {
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: px-to-rem(12);
      line-height: 1.125rem;
    }
    @if $kind == 'text-xs-semibold' {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: px-to-rem(12);
      line-height: 1.125rem;
    }
    @if $kind == 'text-xs-bold' {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: px-to-rem(12);
      line-height: 1.125rem;
    }
  } @else {
    @error 'Text style kind not supported.';
  }
}

// FOCUS STATE

@mixin focus-state() {
  &:focus,
  &:focus-within {
    @include focus-styles;
  }
}

@mixin focus-styles {
  transition: all 0.25s;
  border: 1px solid $purple-300;
  box-shadow: 0 0 0 4px rgb(158 119 237 / 24%), 0 1px 2px 0 rgb(16 24 40 / 5%);
}

// COLOR CLASSES

@mixin generate-color-classes {
  @each $name, $value in $colors {
    .#{$name},
    .#{$name} > * {
      color: $value;
    }
  }
}

// BORDER CLASSES

@mixin gray-rounded-border {
  border: size(1) solid $neutral-200;
  border-radius: $spacing-md;
}

// ICON COLOR MANIPULATION

@mixin override-icon-color($color) {
  svg > path {
    stroke: $color;
  }

  svg > rect,
  svg > circle {
    fill: $color;
  }
}

// FULL WIDTH TABLE
@mixin full-width-table($number-of-columns) {
  table {
    thead tr th {
      @if $number-of-columns == 2 {
        width: 100%;
      } @else {
        &:first-of-type {
          width: 40%;
        }

        &:not(:first-of-type) {
          width: calc(60% / ($number-of-columns - 2));
        }
      }
    }

    @if $number-of-columns != 2 {
      tbody tr td:first-of-type {
        max-width: size(300);
      }
    }
  }
}

@mixin dynamic-full-width-table() {
  & {
    &[style*='--col-span: 2'] {
      table thead tr th {
        width: 100%;
      }
    }

    &[style*='--col-span']:not([style*='--col-span: 2']) {
      table thead tr th {
        &:first-of-type {
          width: 40%;
        }

        &:not(:first-of-type) {
          width: calc(60% / (var(--col-span) - 2));
        }

        tbody tr td:first-of-type {
          max-width: size(300);
        }
      }
    }
  }
}

// Push the trigger element to the right side of the column

@mixin table-with-row-actions {
  table tbody tr td:last-of-type {
    text-align: right;
  }
}

// LINK STYLES

@mixin link-styles {
  @include text-style('text-md-semibold');
  @include focus-state;

  color: $purple-700;
  text-decoration: underline;

  & > *,
  & > * > * {
    stroke: $purple-700;
  }

  &:hover,
  &:focus {
    color: $purple-800;
    cursor: pointer;
    text-decoration: none;

    & > *,
    & > * > * {
      stroke: $purple-800;
    }
  }
}

@mixin new-link-styles {
  @include text-style('text-sm-semibold');
  @include focus-state;

  color: $purple-700;
  text-decoration: none;
  display: flex;
  align-items: center;

  .icon-container {
    padding-left: size(xs);
    display: flex;
  }

  & > *,
  & > * > * {
    stroke: $purple-700;
  }

  &:hover {
    color: $purple-800;

    & > *,
    & > * > * {
      stroke: $purple-800;
    }
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

// BORDER RADIUSES

@mixin border-radius($radius-size) {
  @if map.has-key($radius-sizes, $radius-size) {
    border-radius: map.get($radius-sizes, $radius-size) + px;
  } @else {
    @error 'Radius size not supported.';
  }
}

// BUTTON STYLES
.button-root,
%button-root {
  @include focus-state;
  @include text-style('text-sm-semibold');

  align-items: center;
  border: none;
  border-radius: $spacing-md;
  color: $neutral-700;
  cursor: pointer;
  display: flex;
  gap: size(xxs);
  padding: $sizing-lg $sizing-3xl;
  transition: all 0.25s;
  white-space: nowrap;

  &:disabled {
    cursor: default;
    opacity: 0.3;
    pointer-events: none;
  }

  &-reverse {
    @extend %button-root;

    flex-direction: row-reverse;
  }

  &-icon {
    @extend %button-root;

    padding: size(xs);
  }
}

@mixin button-primary {
  @extend %button-root;
  @include override-icon-color($white);

  background-color: $purple-700;
  color: $white;

  > span {
    color: $white;
  }

  &:hover {
    background-color: $purple-800;
  }
}

@mixin button-secondary {
  @extend %button-root;

  background-color: $white;
  box-shadow: inset 0 0 0 size(1) $neutral-300;

  &:hover {
    background-color: $neutral-200;
  }
}

@mixin button-tertiary {
  @extend %button-root;

  background-color: $white;
  padding: $spacing-md $spacing-lg;
  border-radius: $spacing-md;
  border: 1px solid $neutral-300;

  /* Shadows/shadow-xs */
  box-shadow: $shadow-xs;

  &:hover {
    background-color: $neutral-50;
  }
}

@mixin button-alternate {
  @extend %button-root;

  background-color: $purple-25;
  color: $purple-700;
  border: size(1) solid $purple-200;
  padding: $spacing-md $spacing-lg;

  &:hover {
    background-color: $purple-50;
    color: $purple-800;
  }
}

@mixin button-pill {
  @extend %button-root;
  @include override-icon-color($blue-700);

  background-color: $blue-100;
  color: $blue-700;
  padding: size(xxs) size(xs);
  border-radius: size(6);

  &:hover {
    @include override-icon-color($blue-600);

    background-color: $blue-200;
    color: $blue-600;
  }
}

%state-button {
  @extend %button-root;

  background-color: $white;
  border-radius: $spacing-md;
  border: size(1) solid;
}

@mixin button-success {
  @extend %state-button;

  color: $green-700;
  border-color: $green-700;

  &:hover {
    @include override-icon-color($green-800);

    background-color: $green-100;
    color: $green-800;
    border-color: $green-800;
  }
}

@mixin button-error {
  @extend %state-button;

  color: $red-700;
  border-color: $red-700;

  &:hover {
    @include override-icon-color($red-800);

    background-color: $red-100;
    color: $red-800;
    border-color: $red-800;
  }
}

@mixin button-link {
  @include link-styles;
  @include text-style('text-sm-semibold');

  border: none;
  padding: size(z);
  background: transparent;
}

@mixin button-anchor {
  text-decoration: none;
  width: fit-content;
}

@mixin button-close {
  @include border-radius(md);

  background: transparent;
  border: 1px solid transparent;
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  padding: $spacing-md;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: $neutral-400;
  @include override-icon-color($neutral-400);

  &:hover {
    cursor: pointer;
    background: $neutral-50;
    color: $neutral-500;
    @include override-icon-color($neutral-500);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgb(152 162 179 / 14%);
  }
}

@mixin button-small {
  @extend %button-root;
  @include text-style('text-sm-semibold');

  padding: $sizing-md $sizing-lg;
}

@mixin button-medium {
  @extend %button-root;
  @include text-style('text-sm-semibold');

  padding: $sizing-lg size(14);
}

@mixin button-large {
  @extend %button-root;
  @include text-style('text-md-semibold');

  padding: size(14) $sizing-xl;
}

@mixin button-xl {
  @extend %button-root;
  @include text-style('text-md-semibold');

  padding: $sizing-xl size(18);
}

@mixin button-2xl {
  @extend %button-root;
  @include text-style('text-lg-semibold');

  padding: $sizing-xl size(22);
}

@mixin pill-color($color) {
  $background-color: map.get($colors, '#{$color}' + '-50');
  $text-color: map.get($colors, '#{$color}' + '-700');
  $border-color: map.get($colors, '#{$color}' + '-200');

  color: $text-color;
  border-color: $border-color;
  background-color: $background-color;
}

@mixin pill-color-brand($color) {
  $background-color: map.get($colors, '#{$color}' + '-50');
  $text-color: map.get($colors, '#{$color}' + '-800');
  $border-color: map.get($colors, '#{$color}' + '-200');

  color: $text-color;
  border-color: $border-color;
  background-color: $background-color;
}
