@import 'design-system/styles';

.table {
  @include full-width-table(3);

  table {
    overflow-x: hidden;
    white-space: nowrap;
  }

  .retailer-note {
    max-width: 700px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
