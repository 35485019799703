@import 'design-system/styles';

.fixed {
  @include breakpoint-down('tablet') {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.hidden-mobile {
  @include breakpoint-down('tablet') {
    display: none;
  }
}

.content-container {
  margin: $spacing-4xl 0;
  margin-left: 0; // Default state with secondary nav visible
  transition: margin-left 0.3s ease-out;
}

.content-expanded {
  margin-left: -80px; // expand content to the left when secondary nav is collapsed
  transition: margin-left 0.3s ease-out;

  @include breakpoint-down('tablet') {
    margin-left: 0;
  }
}

.context-collapsed {
  margin-left: 0;
}

.subscription-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-xl;
  margin: $spacing-2xl $spacing-md $spacing-xl 0;
  padding: $spacing-2xl $spacing-xl;
  border-radius: $spacing-md;
  border: size(1) solid $neutral-200;
  color: $neutral-600;
  white-space: normal;
}

.request-to-pay {
  margin-bottom: $spacing-md;
  word-break: break-word;
  overflow-wrap: break-word;
}

.back-button {
  padding: 0 $spacing-sm;
}

.organization-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: normal;
  text-align: left;
}

.mobile-nav-trigger {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: $spacing-lg;
  background: transparent;
  pointer-events: none;
  z-index: 1400;
  width: 100vw; // Force full viewport width including scrollbar
  box-sizing: border-box;

  @include breakpoint-down('tablet') {
    display: block;
  }

  button {
    pointer-events: auto;
    float: right;
    margin-right: $spacing-lg;

    div {
      background-color: $brand-primary-700;
    }
  }
}

.dual-nav-mobile {
  @include breakpoint-down('tablet') {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    z-index: 1300;
  }
}

.item-content {
  display: flex;
  align-items: center;
  gap: size(10);
}
