// BREAKPOINTS

$breakpoints: (
  'mobile': 360,
  'tablet': 744,
  'desktop': 1440,
);
$max-width: 1900px;

// SIZES

$sizes: (
  'z': 0,
  'xxxs': 2,
  'xxs': 4,
  'xs': 8,
  'sm': 16,
  'md': 24,
  'lg': 32,
  'xl': 40,
  'xxl': 48,
);

// NEW SIZING
$sizing-none: 0;
$sizing-xxs: 2px; // 0.125rem
$sizing-xs: 4px; // 0.25rem
$sizing-sm: 6px; // 0.375rem
$sizing-md: 8px; // 0.5rem
$sizing-lg: 12px; // 0.75rem
$sizing-xl: 16px; // 1rem
$sizing-2xl: 20px; // 1.25rem
$sizing-3xl: 24px; // 1.5rem
$sizing-4xl: 32px; // 2rem
$sizing-5xl: 40px; // 2.5rem
$sizing-6xl: 48px; // 3rem
$sizing-7xl: 64px; // 6rem
$sizing-8xl: 80px; // 5rem
$sizing-9xl: 96px; // 6rem
$sizing-10xl: 128px; // 8rem
$sizing-11xl: 160px; // 10rem

// NEW SPACING
$spacing-none: 0;
$spacing-xxs: 2px; // 0.125rem
$spacing-xs: 4px; // 0.25rem
$spacing-sm: 6px; // 0.375rem
$spacing-md: 8px; // 0.5rem
$spacing-lg: 12px; // 0.75rem
$spacing-xl: 16px; // 1rem
$spacing-2xl: 20px; // 1.25rem
$spacing-3xl: 24px; // 1.5rem
$spacing-4xl: 32px; // 2rem
$spacing-5xl: 40px; // 2.5rem
$spacing-6xl: 48px; // 3rem
$spacing-7xl: 64px; // 6rem
$spacing-8xl: 80px; // 5rem
$spacing-9xl: 96px; // 6rem
$spacing-10xl: 128px; // 8rem
$spacing-11xl: 160px; // 10rem

// SPACING (PIXEL VALUES)

$spacings: (
  'none': 0,
  'xxs': 2,
  'xs': 4,
  'sm': 6,
  'md': 8,
  'lg': 12,
  'xl': 16,
  '2xl': 20,
  '3xl': 24,
  '4xl': 32,
  '5xl': 40,
  '6xl': 48,
  '7xl': 64,
  '8xl': 80,
  '9xl': 96,
  '10xl': 128,
  '11xl': 160,
);

// RADIUS SIZES (PIXEL VALUES)

$radius-sizes: (
  'none': 0,
  'xxs': 2,
  'xs': 4,
  'sm': 6,
  'md': 8,
  'lg': 10,
  'xl': 12,
  '2xl': 16,
  '3xl': 20,
  '4xl': 24,
  'full': 9999,
);

// TEXT KINDS

$text-kinds: (
  'display-2xl',
  'display-2xl-medium',
  'display-2xl-bold',
  'display-xl',
  'display-xl-medium',
  'display-xl-bold',
  'display-lg',
  'display-lg-medium',
  'display-lg-bold',
  'display-md',
  'display-md-medium',
  'display-md-bold',
  'display-sm',
  'display-sm-medium',
  'display-sm-bold',
  'display-xs',
  'display-xs-medium',
  'display-xs-bold',
  'display-2xs',
  'display-2xs-medium',
  'display-2xs-bold',
  'text-xl',
  'text-xl-medium',
  'text-xl-semibold',
  'text-xl-bold',
  'text-lg',
  'text-lg-medium',
  'text-lg-semibold',
  'text-lg-bold',
  'text-md',
  'text-md-medium',
  'text-md-semibold',
  'text-md-bold',
  'text-sm',
  'text-sm-medium',
  'text-sm-semibold',
  'text-sm-bold',
  'text-xs',
  'text-xs-medium',
  'text-xs-semibold',
  'text-xs-bold'
);

// Z-INDEXES

// * ------------------------------------------------------------ * //
// * * //
// * ------------------------------------------------------------ * //

$z-index-loading-overlay: 1204;
$z-index-toast: 1403;
$z-index-modal: 1402;
$z-index-tooltip: 1201;

// COLORS

// * --------------------------------------------------------------------------------- * //
// * If any of these change, please update the values in `design-system/data/color.js` * //
// * --------------------------------------------------------------------------------- * //

// Mono
$black: #000;
$white: #fff;

// Neutral
$neutral-25: #fcfcfd;
$neutral-50: #f9fafb;
$neutral-100: #f2f4f7;
$neutral-200: #eaecf0;
$neutral-300: #d0d5dd;
$neutral-400: #98a2b3;
$neutral-500: #667085;
$neutral-600: #475467;
$neutral-700: #344054;
$neutral-800: #182230;
$neutral-900: #101828;
$neutral-1000: #0c111d;

// Purple
$purple-25: #f6f4fc;
$purple-50: #ece9fc;
$purple-100: #d8d3fa;
$purple-200: #b1a6f4;
$purple-300: #8d7aef;
$purple-400: #644de9;
$purple-500: #3d21e5;
$purple-600: #311ab6;
$purple-700: #251489;
$purple-800: #180d5b;
$purple-900: #0c072e;
$purple-1000: #050314;

// Blue
$blue-100: #f2f8ff;
$blue-200: #c5dff8;
$blue-300: #98c5f2;
$blue-400: #5ca3ea;
$blue-500: #2081e2;
$blue-600: #1971c9;
$blue-700: #145799;
$blue-800: #0e3d6c;
$blue-900: #0a2c4e;
$blue-1000: #041321;

// Red
$red-25: #fff5f5;
$red-50: #fef3f2;
$red-100: #fee4e2;
$red-200: #fecdca;
$red-300: #fda29b;
$red-400: #f97066;
$red-500: #f04438;
$red-600: #d92d20;
$red-700: #b42318;
$red-800: #912018;
$red-900: #7a271a;
$red-1000: #55160c;

// Orange
$orange-25: #fffcf5;
$orange-50: #fffaeb;
$orange-100: #fef0c7;
$orange-200: #fedf89;
$orange-300: #fec84b;
$orange-400: #fdb022;
$orange-500: #f79009;
$orange-600: #dc6803;
$orange-700: #b54708;
$orange-800: #93370d;
$orange-900: #7a2e0e;
$orange-1000: #4e1d09;

// Peach
$brand-orange: #ff9879;

// Brand colors
$brand-background: #d3ebed;
$brand-base-white: #fff;
$brand-base-black: #000;

// Brand Primary
$brand-primary-25: #f6f4fc;
$brand-primary-50: #ece9fc;
$brand-primary-100: #d8d3fa;
$brand-primary-200: #b1a6f4;
$brand-primary-300: #8b7aef;
$brand-primary-400: #644de9;
$brand-primary-500: #3d21e5;
$brand-primary-600: #311ab6;
$brand-primary-700: #251489;
$brand-primary-800: #180d5b;
$brand-primary-900: #0c072e;
$brand-primary-950: #050314;

// Brand Secondary
$brand-secondary-25: #f5fcfc;
$brand-secondary-50: #edf4f4;
$brand-secondary-100: #dbe8ea;
$brand-secondary-200: #b7d1d4;
$brand-secondary-300: #93bbbf;
$brand-secondary-400: #6fa4a9;
$brand-secondary-500: #4b8d94;
$brand-secondary-600: #3c7176;
$brand-secondary-700: #2d5559;
$brand-secondary-800: #1e383b;
$brand-secondary-900: #0f1c1e;
$brand-secondary-950: #070e0f;

// Brand Neon
$brand-neon-25: #fefffa;
$brand-neon-50: #fcfff4;
$brand-neon-100: #f9ffe9;
$brand-neon-200: #f3ffd3;
$brand-neon-300: #ecffbd;
$brand-neon-400: #e6ffa7;
$brand-neon-500: #e0ff91;
$brand-neon-600: #b3cc74;
$brand-neon-700: #869957;
$brand-neon-800: #5a663a;
$brand-neon-900: #2d331d;
$brand-neon-950: #16190e;

// Brand Pink
$brand-pink-25: #fef6fa;
$brand-pink-50: #feeff7;
$brand-pink-100: #fddeef;
$brand-pink-200: #fbbbdf;
$brand-pink-300: #f99dce;
$brand-pink-400: #f77cbe;
$brand-pink-500: #f55bae;
$brand-pink-600: #c4498b;
$brand-pink-700: #933768;
$brand-pink-800: #622446;
$brand-pink-900: #311223;
$brand-pink-950: #180911;

// Gray
$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #182230;
$gray-900: #101828;
$gray-950: #0c111d;

// Error
$error-25: #fffbfa;
$error-50: #fef3f2;
$error-100: #fee4e2;
$error-200: #fecdca;
$error-300: #fda29b;
$error-400: #f97066;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;
$error-800: #912018;
$error-900: #7a271a;
$error-950: #55160c;

// Warning
$warning-25: #fffcf5;
$warning-50: #fffaeb;
$warning-100: #fef0c7;
$warning-200: #fedf89;
$warning-300: #fec84b;
$warning-400: #fdb022;
$warning-500: #f79009;
$warning-600: #dc6803;
$warning-700: #b54708;
$warning-800: #93370d;
$warning-900: #7a2e0e;
$warning-950: #4e1d09;

// Success
$success-25: #f6fef9;
$success-50: #ecfdf3;
$success-100: #dcfae6;
$success-200: #abefc6;
$success-300: #75e0a7;
$success-400: #47cd89;
$success-500: #17b26a;
$success-600: #079455;
$success-700: #067647;
$success-800: #085d3a;
$success-900: #074d31;
$success-950: #053321;

// Green
$green-25: #f6fef9;
$green-50: #ecfdf3;
$green-100: #dcfae6;
$green-200: #abefc6;
$green-300: #75e0a7;
$green-400: #47cd89;
$green-500: #17b26a;
$green-600: #079455;
$green-700: #067647;
$green-800: #085d3a;
$green-900: #074d31;
$green-1000: #053321;

// AQUA
$aqua-25: #f6fefe;
$aqua-50: #e9fefd;
$aqua-100: #d4fcfb;
$aqua-200: #a8f9f8;
$aqua-300: #7df7f4;
$aqua-400: #51f4f1;
$aqua-500: #26f1ed;
$aqua-600: #1ec1be;
$aqua-700: #17918e;
$aqua-800: #0f605f;
$aqua-900: #08302f;

// PEACH
$peach-25: #fff9f7;
$peach-50: #fff2ee;
$peach-100: #ffe5de;
$peach-200: #ffcbbc;
$peach-300: #ffb29b;
$peach-400: #ff9879;
$peach-500: #ff7e58;
$peach-600: #cc6546;
$peach-700: #994c35;
$peach-800: #663223;
$peach-900: #331912;

// SLATE
$slate-25: #f0f0f0;
$slate-50: #e8eaf0;
$slate-100: #d0d5e0;
$slate-200: #a1aac1;
$slate-300: #7280a2;
$slate-400: #435583;
$slate-500: #142b64;
$slate-600: #102250;
$slate-700: #0c1a3c;
$slate-800: #081128;
$slate-900: #040914;

// LAPIS
$lapis-25: #f4f5fc;
$lapis-50: #e9ebfc;
$lapis-100: #d3d6fa;
$lapis-200: #a6aef4;
$lapis-300: #7a85ef;
$lapis-400: #4d5de9;
$lapis-500: #2134e4;
$lapis-600: #1a2ab6;
$lapis-700: #141f89;
$lapis-800: #0d155b;
$lapis-900: #070a2e;

// For mixin use
$colors: (
  'white': $white,
  'black': $black,
  'neutral-25': $neutral-25,
  'neutral-50': $neutral-50,
  'neutral-100': $neutral-100,
  'neutral-200': $neutral-200,
  'neutral-300': $neutral-300,
  'neutral-400': $neutral-400,
  'neutral-500': $neutral-500,
  'neutral-600': $neutral-600,
  'neutral-700': $neutral-700,
  'neutral-800': $neutral-800,
  'neutral-900': $neutral-900,
  'neutral-1000': $neutral-1000,
  'purple-25': $purple-25,
  'purple-50': $purple-50,
  'purple-100': $purple-100,
  'purple-200': $purple-200,
  'purple-300': $purple-300,
  'purple-400': $purple-400,
  'purple-500': $purple-500,
  'purple-600': $purple-600,
  'purple-700': $purple-700,
  'purple-800': $purple-800,
  'purple-900': $purple-900,
  'purple-1000': $purple-1000,
  'blue-100': $blue-100,
  'blue-200': $blue-200,
  'blue-300': $blue-300,
  'blue-400': $blue-400,
  'blue-500': $blue-500,
  'blue-600': $blue-600,
  'blue-700': $blue-700,
  'blue-800': $blue-800,
  'blue-900': $blue-900,
  'blue-1000': $blue-1000,
  'orange-25': $orange-25,
  'orange-50': $orange-50,
  'orange-100': $orange-100,
  'orange-200': $orange-200,
  'orange-300': $orange-300,
  'orange-400': $orange-400,
  'orange-500': $orange-500,
  'orange-600': $orange-600,
  'orange-700': $orange-700,
  'orange-800': $orange-800,
  'orange-900': $orange-900,
  'orange-1000': $orange-1000,
  'brand-orange': $brand-orange,
  'red-25': $red-25,
  'red-50': $red-50,
  'red-100': $red-100,
  'red-200': $red-200,
  'red-300': $red-300,
  'red-400': $red-400,
  'red-500': $red-500,
  'red-600': $red-600,
  'red-700': $red-700,
  'red-800': $red-800,
  'red-900': $red-900,
  'red-1000': $red-1000,
  'green-25': $green-25,
  'green-50': $green-50,
  'green-100': $green-100,
  'green-200': $green-200,
  'green-300': $green-300,
  'green-400': $green-400,
  'green-500': $green-500,
  'green-600': $green-600,
  'green-700': $green-700,
  'green-800': $green-800,
  'green-900': $green-900,
  'green-1000': $green-1000,
  'aqua-25': $aqua-25,
  'aqua-50': $aqua-50,
  'aqua-100': $aqua-100,
  'aqua-200': $aqua-200,
  'aqua-300': $aqua-300,
  'aqua-400': $aqua-400,
  'aqua-500': $aqua-500,
  'aqua-600': $aqua-600,
  'aqua-700': $aqua-700,
  'aqua-800': $aqua-800,
  'aqua-900': $aqua-900,
  'peach-25': $peach-25,
  'peach-50': $peach-50,
  'peach-100': $peach-100,
  'peach-200': $peach-200,
  'peach-300': $peach-300,
  'peach-400': $peach-400,
  'peach-500': $peach-500,
  'peach-600': $peach-600,
  'peach-700': $peach-700,
  'peach-800': $peach-800,
  'peach-900': $peach-900,
  'slate-25': $slate-25,
  'slate-50': $slate-50,
  'slate-100': $slate-100,
  'slate-200': $slate-200,
  'slate-300': $slate-300,
  'slate-400': $slate-400,
  'slate-500': $slate-500,
  'slate-600': $slate-600,
  'slate-700': $slate-700,
  'slate-800': $slate-800,
  'slate-900': $slate-900,
  'lapis-25': $lapis-25,
  'lapis-50': $lapis-50,
  'lapis-100': $lapis-100,
  'lapis-200': $lapis-200,
  'lapis-300': $lapis-300,
  'lapis-400': $lapis-400,
  'lapis-500': $lapis-500,
  'lapis-600': $lapis-600,
  'lapis-700': $lapis-700,
  'lapis-800': $lapis-800,
  'lapis-900': $lapis-900,

  'brand-primary-25': $brand-primary-25,
  'brand-primary-50': $brand-primary-50,
  'brand-primary-100': $brand-primary-100,
  'brand-primary-200': $brand-primary-200,
  'brand-primary-300': $brand-primary-300,
  'brand-primary-400': $brand-primary-400,
  'brand-primary-500': $brand-primary-500,
  'brand-primary-600': $brand-primary-600,
  'brand-primary-700': $brand-primary-700,
  'brand-primary-800': $brand-primary-800,
  'brand-primary-900': $brand-primary-900,
  'brand-primary-950': $brand-primary-950,

  'brand-secondary-25': $brand-secondary-25,
  'brand-secondary-50': $brand-secondary-50,
  'brand-secondary-100': $brand-secondary-100,
  'brand-secondary-200': $brand-secondary-200,
  'brand-secondary-300': $brand-secondary-300,
  'brand-secondary-400': $brand-secondary-400,
  'brand-secondary-500': $brand-secondary-500,
  'brand-secondary-600': $brand-secondary-600,
  'brand-secondary-700': $brand-secondary-700,
  'brand-secondary-800': $brand-secondary-800,
  'brand-secondary-900': $brand-secondary-900,
  'brand-secondary-950': $brand-secondary-950,

  'brand-neon-25': $brand-neon-25,
  'brand-neon-50': $brand-neon-50,
  'brand-neon-100': $brand-neon-100,
  'brand-neon-200': $brand-neon-200,
  'brand-neon-300': $brand-neon-300,
  'brand-neon-400': $brand-neon-400,
  'brand-neon-500': $brand-neon-500,
  'brand-neon-600': $brand-neon-600,
  'brand-neon-700': $brand-neon-700,
  'brand-neon-800': $brand-neon-800,
  'brand-neon-900': $brand-neon-900,
  'brand-neon-950': $brand-neon-950,

  'brand-pink-25': $brand-pink-25,
  'brand-pink-50': $brand-pink-50,
  'brand-pink-100': $brand-pink-100,
  'brand-pink-200': $brand-pink-200,
  'brand-pink-300': $brand-pink-300,
  'brand-pink-400': $brand-pink-400,
  'brand-pink-500': $brand-pink-500,
  'brand-pink-600': $brand-pink-600,
  'brand-pink-700': $brand-pink-700,
  'brand-pink-800': $brand-pink-800,
  'brand-pink-900': $brand-pink-900,
  'brand-pink-950': $brand-pink-950,

  'gray-25': $gray-25,
  'gray-50': $gray-50,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'gray-950': $gray-950,

  'error-25': $error-25,
  'error-50': $error-50,
  'error-100': $error-100,
  'error-200': $error-200,
  'error-300': $error-300,
  'error-400': $error-400,
  'error-500': $error-500,
  'error-600': $error-600,
  'error-700': $error-700,
  'error-800': $error-800,
  'error-900': $error-900,
  'error-950': $error-950,

  'warning-25': $warning-25,
  'warning-50': $warning-50,
  'warning-100': $warning-100,
  'warning-200': $warning-200,
  'warning-300': $warning-300,
  'warning-400': $warning-400,
  'warning-500': $warning-500,
  'warning-600': $warning-600,
  'warning-700': $warning-700,
  'warning-800': $warning-800,
  'warning-900': $warning-900,
  'warning-950': $warning-950,

  'success-25': $success-25,
  'success-50': $success-50,
  'success-100': $success-100,
  'success-200': $success-200,
  'success-300': $success-300,
  'success-400': $success-400,
  'success-500': $success-500,
  'success-600': $success-600,
  'success-700': $success-700,
  'success-800': $success-800,
  'success-900': $success-900,
  'success-950': $success-950,
);

// SHADOWS

// * --------------------------------------------------------------------------------- * //
// * If any of these change, please update the values in `design-system/data/shadow.js` * //
// * --------------------------------------------------------------------------------- * //
$shadow-elevation-low: 0 0.3px 0.5px hsl(0deg 0% 56% / 0%),
  0 0.6px 0.9px hsl(0deg 0% 56% / 28%), 0 1.4px 2.1px hsl(0deg 0% 56% / 55%);
$shadow-elevation-medium: 0 0.3px 0.5px hsl(0deg 0% 56% / 0%),
  0 1.7px 2.6px hsl(0deg 0% 56% / 22%), 0 3.4px 5.1px hsl(0deg 0% 56% / 44%),
  -0.1px 6.9px 10.4px hsl(0deg 0% 56% / 65%);
$shadow-elevation-high: 0 0.3px 0.5px hsl(0deg 0% 56% / 0%),
  -0.1px 3.9px 5.9px hsl(0deg 0% 56% / 10%),
  -0.1px 6.9px 10.4px hsl(0deg 0% 56% / 20%),
  -0.1px 10.1px 15.2px hsl(0deg 0% 56% / 30%),
  -0.2px 14.2px 21.3px hsl(0deg 0% 56% / 40%),
  -0.3px 19.7px 29.6px hsl(0deg 0% 56% / 51%),
  -0.4px 27.3px 41px hsl(0deg 0% 56% / 61%),
  -0.6px 37.8px 56.7px hsl(0deg 0% 56% / 71%);

/* V2 Shadows/shadow-sm */
$shadow-xs: 0 1px 2px 0 rgb(16 24 40 / 5%);
$shadow-sm: 0 1px 2px 0 rgb(16 24 40 / 6%), 0 1px 3px 0 rgb(16 24 40 / 10%);
$shadow-md: 0 2px 4px -2px rgb(16 24 40 / 6%),
  0 4px 8px -2px rgb(16 24 40 / 10%);
$shadow-lg: 0 4px 6px -2px rgb(16 24 40 / 3%),
  0 12px 16px -4px rgb(16 24 40 / 8%);
$shadow-xl: 0 24px 48px -12px rgb(16 24 40 / 18%);
$shadow-2xl: 0 24px 48px -12px rgb(16 24 40 / 18%); // same as xl - check with design.
$shadow-3xl: 0 32px 64px -12px rgb(16 24 40 / 14%);
