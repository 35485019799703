@import 'design-system/styles';

.container,
.pages,
.pages > button,
.pages > li > button,
.ellipsis {
  display: flex;
}

.pages > button,
.pages > li > button,
.ellipsis {
  align-items: center;
  justify-content: center;
  width: spacing('4xl');
}

ul.container {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;

  > li:first-child {
    margin-right: spacing('md');
  }

  > li:last-child {
    margin-left: spacing('md');
  }
}

.container {
  justify-content: space-between;

  li {
    list-style: none;
  }
}

.show-item-count-container {
  display: flex;
  align-items: center;
  gap: spacing('md');
}

.pages {
  gap: spacing(xs);

  & > * > button {
    border: none;
    box-shadow: none;

    & > span {
      @include text-style('text-sm-medium');
    }

    &:disabled {
      opacity: 1;
      background-color: $neutral-100;

      & > span {
        @include text-style('text-sm-bold');
      }
    }
  }
}

.ellipsis {
  margin: 0;
  justify-content: center;
}
