@import 'design-system/styles';

.container {
  min-height: 100%;
  padding-top: $sizing-5xl;

  h2 {
    margin-top: $spacing-5xl;
  }

  a {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal;
    }
  }

  .cards {
    width: 100%;

    .loading,
    .portal-card {
      width: 100%;
      margin-bottom: $sizing-xl;

      .programs-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-xl;

        .badges-container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: $spacing-lg;
          margin-top: $sizing-lg;

          .badges-wrapper {
            display: flex;
            gap: $spacing-xs;
          }
        }
      }
    }

    .loading {
      margin-top: $spacing-5xl;
    }

    .portal-card,
    .recommended-card {
      padding: $sizing-3xl;
      border-radius: $sizing-lg;
      border: 1px solid $neutral-200;
      box-shadow: $shadow-xs;
      background-color: $white;
      justify-content: space-between;

      .logo {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 60%;
        display: block;
        align-items: center;

        img {
          width: auto;
          max-width: 100%;
          max-height: 3.5rem;
          margin-bottom: $spacing-3xl;
        }
      }

      hr {
        margin: $sizing-xl 0;
        margin-left: calc(-1 * $sizing-3xl);
        margin-right: calc(-1 * $sizing-3xl);
        border: 0;
        border-top: 1px solid $neutral-200;
      }

      a {
        display: inline-block;
      }

      .cta {
        display: flex;
        justify-content: flex-end;
      }
    }

    .recommended-card {
      display: flex;
      padding: $spacing-4xl;
      margin: $spacing-3xl 4.5rem $spacing-xl 4.5rem;
      gap: $spacing-4xl;
      border: 1px solid $brand-primary-200;
      background: $brand-primary-25;
      border-radius: $spacing-md;

      // Stack elements on smaller screens
      @include breakpoint-down('desktop') {
        flex-direction: column;
        padding: $spacing-3xl;
        gap: $spacing-3xl;
      }

      @include breakpoint-down('tablet') {
        margin-left: 0;
        margin-right: 0;
      }

      .images {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        img {
          max-width: 200px;
          height: auto;
        }
      }

      .copy {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $spacing-xl;
      }

      .ctas {
        display: flex;
        gap: $spacing-xl;
        align-items: flex-start;

        @include breakpoint-down('tablet') {
          flex-direction: column;
          width: 100%;

          a {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  .icon-container {
    padding-left: $spacing-md;
    display: flex;
  }
}

.section {
  width: 100%;

  .header {
    margin-bottom: $spacing-4xl;
  }
}

.workbench {
  margin-bottom: $spacing-8xl;

  .header {
    margin-bottom: $spacing-4xl;

    h2 {
      margin-bottom: $spacing-md;
    }
  }

  .tool {
    margin-bottom: $spacing-xl;
    display: flex;
    align-items: center;
    gap: $spacing-xl;
    padding: $spacing-3xl;
    border: 1px solid $brand-primary-25;
    border-radius: $spacing-md;
    text-decoration: none;
    box-shadow: $shadow-xs;

    img {
      width: auto;
      height: $sizing-9xl;
    }

    .content {
      div {
        display: block;
      }

      flex: 1;

      h3 {
        margin-bottom: $spacing-xs;
      }
    }

    .arrow {
      color: $brand-primary-700;
      font-size: $sizing-2xl;
      transition: transform 0.2s ease;

      &:hover {
        background: $brand-primary-50;
        transform: scale(1.1);
      }

      @include button-alternate;
    }
  }
}
